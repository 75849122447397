<template>
  <section class="section--region">
    <div class="form--group" :class="{ 'has-error': validation.hasError('province') }">
      <div class="left--col">
        <label for="province">
          <span>{{ $t('profile.province') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <client-only>
          <multiselect
            id="province"
            v-model="province"
            select-label=""
            deselect-label=""
            track-by="id"
            label="name"
            :searchable="true"
            :close-on-select="true"
            :show-labels="true"
            @input="getCities"
            :options="provinces"
          >
          </multiselect>
          <span class="val-error" v-if="validation.hasError('province')">{{
            validation.firstError('province')
          }}</span>
        </client-only>
      </div>
    </div>
    <div class="form--group" :class="{ 'has-error': validation.hasError('city') }">
      <div class="left--col">
        <label for="city">
          <span>{{ $t('profile.city') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <client-only>
          <multiselect
            id="city"
            v-model="city"
            select-label=""
            deselect-label=""
            track-by="id"
            label="name"
            @input="getDistricts"
            :options="cities"
          ></multiselect>
          <span class="val-error" v-if="validation.hasError('city')">{{
            validation.firstError('city')
          }}</span>
        </client-only>
      </div>
    </div>
    <div class="form--group" :class="{ 'has-error': validation.hasError('district') }">
      <div class="left--col">
        <label for="district">
          <span
            v-html="villageRequired ? $t('profile.districtRequired') : $t('profile.district')"
          ></span>
          <!--          <span class="required">*</span>-->
        </label>
      </div>
      <div class="right--col">
        <client-only>
          <multiselect
            id="district"
            v-model="district"
            select-label=""
            deselect-label=""
            track-by="id"
            label="name"
            @input="getVillages"
            :options="districts"
          ></multiselect>
          <span class="val-error" v-if="validation.hasError('district')">{{
            validation.firstError('district')
          }}</span>
        </client-only>
      </div>
    </div>
    <div class="form--group" :class="{ 'has-error': validation.hasError('village') }">
      <div class="left--col">
        <label for="village">
          <span
            v-html="villageRequired ? $t('profile.villageRequired') : $t('profile.village')"
          ></span>
          <!--          <span class="required">*</span>-->
        </label>
      </div>
      <div class="right--col">
        <client-only>
          <multiselect
            id="village"
            v-model="village"
            select-label=""
            deselect-label=""
            track-by="id"
            label="name"
            :options="villages"
          ></multiselect>
          <span class="val-error" v-if="validation.hasError('village')">{{
            validation.firstError('village')
          }}</span>
        </client-only>
      </div>
    </div>
  </section>
</template>

<script>
import { Validator } from 'simple-vue-validator';

import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
export default {
  name: 'regionalForm',
  props: {
    villageRequired: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      provinces: [],
      cities: [],
      districts: [],
      villages: [],
      province: null,
      city: null,
      district: null,
      village: null,
    };
  },
  validators: {
    province(value) {
      if (value === undefined) value = null;
      return Validator.value(value).required(this.$i18n.t('errors.province_id.required'));
    },
    city(value) {
      if (value === undefined) value = null;
      return Validator.value(value).required(this.$i18n.t('errors.city_id.required'));
    },
    district(value) {
      if (!this.villageRequired) return;
      if (value === undefined) value = null;
      return Validator.value(value).required(this.$i18n.t('errors.district_id.required'));
    },
    village(value) {
      if (!this.villageRequired) return;
      if (value === undefined) value = null;
      return Validator.value(value).required(this.$i18n.t('errors.village_id.required'));
    },
  },
  mounted() {
    this.$emit('loaded');
  },
  methods: {
    validate() {
      return this.$validate().then(
        function (success) {
          return !!success;
        }.bind(this),
      );
    },
    async getCities(province) {
      this.city = null;
      this.district = null;
      this.districts = [];
      this.village = null;
      this.villages = [];

      if (province && province.id) {
        let response = await this.$store.dispatch('v2/masters/getCitiesByProvinceId', province.id);
        this.cities = response;
      } else {
        this.cities = [];
      }
    },
    async getDistricts(city) {
      this.district = null;
      this.village = null;
      this.villages = [];

      if (city && city.id) {
        let response = await this.$store.dispatch('v2/masters/getDistrictsByCityId', city.id);
        this.districts = response;
      } else {
        this.districts = [];
      }
    },
    async getVillages(district) {
      this.village = null;

      if (district && district.id) {
        let response = await this.$store.dispatch(
          'v2/masters/getVillagesByDistrictId',
          district.id,
        );
        this.villages = response;
      } else {
        this.villages = [];
      }
    },
  },
};
</script>
